<template>
  <component :is="withModal ? BModal : 'div'"
             v-show="props.show"
             :visible="props.show"
             size="lg"
             :title="$t('class-module.script.title')"
             :hide-footer="!props.isHighLevelUser"
             @close="$emit('close')"
             @hide="$emit('close')"
  >

    <div
      v-if="!isClassRoom"
      class="bg-danger position-absolute mr-2 cursor-pointer d-flex justify-content-center align-items-center rounded-circle shadow"
      style="width:18px; height:18px; right:0px; z-index:100; top:5px;"
      @click="$emit('close')"
    >
      <feather-icon
        icon="XIcon"
        size="14"
        class="text-white"
      />
    </div>
    <b-tabs v-if="props.isHighLevelUser">
      <b-tab :title="$t('class-module.script.update-script')">
        <b-form-group :label="$t('script-name')">
          <b-form-input v-model="state.script_name"
                        :placeholder="$t('script-name')"
          />
        </b-form-group>
        <editor v-model="state.description" />
        <b-form-checkbox v-model="state.is_public"
                         switch
                         :value="1"
                         class="mt-1"
        >
          {{ $t('class-module.script.make-public') }}
        </b-form-checkbox>
      </b-tab>
      <b-tab :title="$t('class-module.script.preview-script')">
        <div v-html="state.description" />
      </b-tab>
      <b-tab :title="$t('class-module.script.public-script')"
             lazy
      >
        <PublicScript :class-id="props.classId"
                      @refetch="getClassRoomScript"
        />
      </b-tab>
    </b-tabs>
    <div v-else-if="state.description">
      <div v-html="state.description" />
    </div>
    <div v-else>
      <BCard>
        {{ $t('class-module.script.no-script-added') }}
      </BCard>
    </div>
    <ScoreBoard v-if="!isClassRoom && !withModal"
                :default-class-room-id="classId"
    />
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-end">
        <b-button
          variant="success"
          :disabled="state.isProcessing"
          class="mr-1"
          @click="updateScript"
        >
          <b-spinner
            v-show="state.isProcessing"
            small
          />
          {{ $t('actions.save') }}
        </b-button>
      </div>
    </template>
    <template v-if="isClassRoom">
      <div class="w-100 d-flex justify-content-end">
        <b-button
          variant="success"
          :disabled="state.isProcessing"
          class="mr-1"
          @click="updateScript"
        >
          <b-spinner
            v-show="state.isProcessing"
            small
          />
          {{ $t('actions.save') }}
        </b-button>
      </div>
    </template>
  </component>
</template>
<script setup>
import {
  BModal, BButton, BSpinner, BTabs, BTab, BCard, BFormGroup, BFormInput, BFormCheckbox,
} from 'bootstrap-vue'
import {
  reactive, getCurrentInstance, watch, onMounted,
} from 'vue'
import useJwt from '@/auth/jwt/useJwt'
import Editor from '@views/common/components/QuillEditorCustom.vue'
import ScoreBoard from '@/views/v2/common/class/components/StudentScoreBoard.vue'
import PublicScript from './PublicScript.vue'

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  classId: {
    type: Number,
    default: 0,
  },
  isHighLevelUser: {
    type: Boolean,
    default: false,
  },
  withModal: {
    type: Boolean,
    default: true,
  },
  isClassRoom: {
    type: Boolean,
    default: false,
  },
})

const state = reactive({
  isProcessing: false,
  description: '',
  script_name: '',
  is_public: false,
})

function getClassRoomScript() {
  useJwt.getClassroomScript(props.classId).then(response => {
    state.description = response.data.data?.description
    state.script_name = response.data.data?.script_name
    state.is_public = response.data.data?.is_public
  })
}

onMounted(() => {
  getClassRoomScript()
})

const root = getCurrentInstance().proxy.$root

function updateScript() {
  state.isProcessing = true
  useJwt.updateClassroomScript(props.classId, {
    class_id: props.classId,
    description: state.description,
    script_name: state.script_name,
    is_public: state.is_public,
  }).then(response => {
    root.showSuccessMessage(response)
  }).catch(error => {
    root.showErrorMessage(error)
  }).finally(() => {
    state.isProcessing = false
  })
}

watch(() => props.classId, (first, second) => {
  if (second) {
    useJwt.getClassroomScript(props.classId).then(response => {
      state.description = response.data.data?.description
    })
  }
})

</script>
